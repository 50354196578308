import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateFileRequest, CreateFileResponse, FileListItem, FileResponse, FileURL } from '@models/index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class FilesService {
  private readonly url = `${environment.bffUrl}/file-management/files`;
  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<FileListItem[]> {
    return this.httpClient.get<FileListItem[]>(this.url);
  }

  get(id: string): Observable<FileResponse> {
    return this.httpClient.get<FileResponse>(`${this.url}/${id}`);
  }

  add(file: CreateFileRequest): Observable<CreateFileResponse> {
    return this.httpClient.post<CreateFileResponse>(this.url, file);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  activate(id: string): Observable<FileResponse> {
    return this.httpClient.post<FileResponse>(`${this.url}/${id}/activate`, null);
  }

  getDownloadUrl(id: string): Observable<FileURL> {
    return this.httpClient.get<FileURL>(`${this.url}/${id}/download-url`);
  }
}
