import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssessmentListItem, AssessmentResponse, AssessmentStatusFilter, DownloadURL } from '@models/products-and-orders/assessments';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AssessmentsService {
  private readonly path = (url = ''): string => `${environment.bffUrl}/assessments/${url}`;

  constructor(private httpClient: HttpClient) {}

  getAll(status?: AssessmentStatusFilter): Observable<AssessmentListItem[]> {
    return this.httpClient.get<AssessmentListItem[]>(this.path(), {
      params: {
        ...(status && { status: this.convertToMultiValuedParam<AssessmentStatusFilter>(status) }),
      },
    });
  }

  get(id: string): Observable<AssessmentResponse> {
    return this.httpClient.get<AssessmentResponse>(this.path(`${id}`));
  }

  getDownloadUrl(id: string, fileId: string): Observable<DownloadURL> {
    return this.httpClient.get<DownloadURL>(this.path(`${id}/files/${fileId}/download-url`));
  }

  private convertToMultiValuedParam<T extends string[]>(values: T): string {
    return values.join('|');
  }
}
