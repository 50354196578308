import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

export const selectModuleState = createSelector(getRootState, rootState => rootState.assessments);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectAssessmentListIsLoading = createSelector(selectModuleState, state => state.assessmentListIsLoading);
export const selectToggle = createSelector(selectModuleState, state => state.toggle);
export const selectAssessmentList = createSelector(selectModuleState, state => state.assessments);
export const selectSelectedAssessment = createSelector(selectModuleState, state => state.selectedAssessment);
export const selectSelectedAssessmentId = createSelector(selectModuleState, state => state.selectedAssessment?.id ?? null);
export const selectSelectedAssessmentFiles = createSelector(selectModuleState, state => state.selectedAssessment?.files ?? []);
