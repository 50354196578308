import { HttpErrorResponse } from '@angular/common/http';
import { ProductRequest } from '@models/products-and-orders/requests';
import { createAction, props } from '@ngrx/store';
import { State } from './requests.reducer';

const NAMESPACE = '[Requests]';

export const getAll = createAction(`${NAMESPACE} GetAll`, props<{ isActive: boolean }>());
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ requests: ProductRequest[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const selectRequest = createAction(`${NAMESPACE} SelectRequest`, props<{ id: string }>());
export const selectRequestComplete = createAction(`${NAMESPACE} SelectRequestComplete`, props<{ request: ProductRequest }>());
export const selectRequestError = createAction(`${NAMESPACE} SelectRequestError`, props<{ err: HttpErrorResponse }>());

export const goToRequestActions = createAction(`${NAMESPACE} GoToRequestActions`, props<{ id: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
