import { HttpErrorResponse } from '@angular/common/http';
import { CreateFileRequest, CreateFileResponse, FileListItem, FileResponse } from '@models/index';
import { createAction, props } from '@ngrx/store';
import { State } from './files.reducer';

const NAMESPACE = '[FileManagement Files]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ files: FileListItem[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addFile = createAction(`${NAMESPACE} AddFile`, props<{ fileReq: CreateFileRequest; file: File }>());
export const addFileComplete = createAction(`${NAMESPACE} AddFileComplete`, props<{ fileRes: FileResponse }>());
export const addFileError = createAction(`${NAMESPACE} AddFileError`, props<{ err: HttpErrorResponse }>());

export const uploadFile = createAction(`${NAMESPACE} UploadFile`, props<{ response: CreateFileResponse; file: File }>());

export const activateFile = createAction(`${NAMESPACE} ActivateFile`, props<{ id: string }>());

export const downloadSelectedFile = createAction(`${NAMESPACE} DownloadSelectedFile`);
export const downloadSelectedFileComplete = createAction(`${NAMESPACE} DownloadSelectedFileComplete`, props<{ url: string }>());
export const downloadSelectedFileError = createAction(`${NAMESPACE} DownloadSelectedFileError`);

export const deleteSelectedFile = createAction(`${NAMESPACE} DeleteSelectedFile`);
export const deleteSelectedFileComplete = createAction(`${NAMESPACE} DeleteSelectedFileComplete`);
export const deleteSelectedFileError = createAction(`${NAMESPACE} DeleteSelectedFileError`, props<{ err: HttpErrorResponse }>());

export const selectFile = createAction(`${NAMESPACE} SelectFile`, props<{ id: string }>());
export const selectFileComplete = createAction(`${NAMESPACE} SelectFileComplete`, props<{ fileRes: FileResponse }>());
export const selectFileError = createAction(`${NAMESPACE} SelectFileError`, props<{ err: HttpErrorResponse }>());

export const goToFileActions = createAction(`${NAMESPACE} GoToFileActions`, props<{ id: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
