import { Agreement, AgreementListItem, AgreementLogListItem, AgreementVersion, AgreementVersionListItem } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, sortAlphabetically } from '../helpers/helper';
import * as fromActions from './agreements.actions';
export interface State {
  agreements: AgreementListItem[];
  selectedAgreement: Agreement | null;
  logs: AgreementLogListItem[];
  versions: AgreementVersionListItem[];
  selectedVersion: AgreementVersion | null;
  isLoading: boolean;
  isSelectedAgreementLoading: boolean;
  isSelectedAgreementVersionLoading: boolean;
  isLogsLoading: boolean;
  isVersionsLoading: boolean;
}

export const initialState: State = {
  agreements: [],
  selectedAgreement: null,
  selectedVersion: null,
  logs: [],
  versions: [],
  isLoading: false,
  isSelectedAgreementLoading: false,
  isSelectedAgreementVersionLoading: false,
  isLogsLoading: false,
  isVersionsLoading: false,
};

export const agreementsReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllAgreements, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllAgreementsComplete, (state, { agreements }) => ({
    ...state,
    isLoading: false,
    agreements: sortAlphabetically(agreements, (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getAllAgreementsError, state => ({ ...state, isLoading: false })),

  on(fromActions.getLogs, state => ({ ...state, isLogsLoading: true })),
  on(fromActions.getLogsComplete, (state, { logs }) => ({ ...state, isLogsLoading: false, logs })),
  on(fromActions.getLogsError, state => ({ ...state, isLogsLoading: false })),

  on(fromActions.getVersions, state => ({ ...state, isVersionsLoading: true })),
  on(fromActions.getVersionsComplete, (state, { versions }) => ({ ...state, isVersionsLoading: false, versions })),
  on(fromActions.getVersionsError, state => ({ ...state, isVersionsLoading: false })),

  on(fromActions.selectAgreement, state => ({ ...state, isSelectedAgreementLoading: true })),
  on(fromActions.selectAgreementComplete, (state, { agreement }) => ({
    ...state,
    isSelectedAgreementLoading: false,
    selectedAgreement: agreement,
  })),
  on(fromActions.selectAgreementError, state => ({
    ...state,
    isSelectedAgreementLoading: false,
  })),

  on(fromActions.selectAgreementVersion, state => ({ ...state, isSelectedAgreementVersionLoading: true })),
  on(fromActions.selectAgreementVersionComplete, (state, { version }) => ({
    ...state,
    isSelectedAgreementVersionLoading: false,
    selectedVersion: version,
  })),
  on(fromActions.selectAgreementVersionError, state => ({
    ...state,
    isSelectedAgreementVersionLoading: false,
  })),

  on(fromActions.addNewAgreementVersion, state => ({ ...state, isVersionsLoading: true, selectedVersion: null })),
  on(fromActions.addNewAgreementVersionComplete, (state, { version }) => {
    const { content, ...rest } = version;
    const versionListItem: AgreementVersionListItem = rest;
    return {
      ...state,
      isVersionsLoading: false,
      selectedVersion: version,
      versions: [...state.versions, versionListItem],
    };
  }),
  on(fromActions.addNewAgreementVersionsError, state => ({ ...state, isVersionsLoading: false })),

  on(fromActions.addNewAgreement, state => ({ ...state, isLoading: true, selectedAgreement: null })),
  on(fromActions.addNewAgreementComplete, (state, { agreement }) => ({
    ...state,
    isLoading: false,
    agreements: sortAlphabetically([...state.agreements, agreement], (a, b) => a.name.localeCompare(b.name)),
    selectedAgreement: agreement,
  })),
  on(fromActions.addNewAgreementError, state => ({ ...state, isLoading: false })),

  on(fromActions.updateAgreement, state => ({ ...state, isSelectedAgreementLoading: true, isLoading: true })),
  on(fromActions.updateAgreementComplete, (state, { agreement }) => ({
    ...state,
    selectedAgreement: agreement,
    agreements: sortAlphabetically(
      findAndReplace<AgreementListItem>(state.agreements, agreement, item => item.id === agreement.id),
      (a, b) => a.name.localeCompare(b.name),
    ),
    isSelectedAgreementLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateAgreementError, state => ({
    ...state,
    selectedAgreement: { ...state.selectedAgreement! },
    isSelectedAgreementLoading: false,
    isLoading: false,
  })),

  on(fromActions.updateAgreementVersion, state => ({ ...state, isSelectedAgreementVersionLoading: true, isVersionsLoading: true })),
  on(fromActions.updateAgreementVersionComplete, (state, { version }) => {
    const { content, ...rest } = version;
    return {
      ...state,
      selectedVersion: version,
      versions: findAndReplace<AgreementVersionListItem>(
        state.versions,
        rest,
        item => item.agreementId === rest.agreementId && item.id === rest.id,
      ),
      isSelectedAgreementVersionLoading: false,
      isVersionsLoading: false,
    };
  }),
  on(fromActions.updateAgreementVersionError, state => ({
    ...state,
    selectedVersion: { ...state.selectedVersion! },
    isSelectedAgreementVersionLoading: false,
    isVersionsLoading: false,
  })),

  on(fromActions.publishSelectedAgreementVersion, state => ({
    ...state,
    isSelectedAgreementVersionLoading: true,
    isVersionsLoading: true,
  })),
  on(fromActions.publishSelectedAgreementVersionComplete, (state, { version }) => {
    const { content, ...rest } = version;
    return {
      ...state,
      selectedVersion: version,
      versions: findAndReplace<AgreementVersionListItem>(
        state.versions,
        rest,
        item => item.agreementId === rest.agreementId && item.id === rest.id,
      ),
      isSelectedAgreementVersionLoading: false,
      isVersionsLoading: false,
    };
  }),
  on(fromActions.publishSelectedAgreementVersionError, state => ({
    ...state,
    selectedVersion: { ...state.selectedVersion! },
    isSelectedAgreementVersionLoading: false,
    isVersionsLoading: false,
  })),

  on(fromActions.deleteSelectedAgreement, state => ({ ...state, isSelectedAgreementLoading: true })),
  on(fromActions.deleteSelectedAgreementComplete, state => ({
    ...state,
    isSelectedAgreementLoading: false,
    selectedAgreement: null,
    selectedVersion: null,
  })),
  on(fromActions.deleteSelectedAgreementError, state => ({
    ...state,
    isSelectedAgreementLoading: false,
  })),

  on(fromActions.deleteSelectedAgreementVersion, state => ({ ...state, isSelectedAgreementVersionLoading: true })),
  on(fromActions.deleteSelectedAgreementVersionComplete, state => ({
    ...state,
    isSelectedAgreementVersionLoading: false,
    selectedVersion: null,
  })),
  on(fromActions.deleteSelectedAgreementVersionError, state => ({
    ...state,
    isSelectedAgreementVersionLoading: false,
  })),
);
