import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { GroupedRoles, Permission, RoleType, UpdateRoleForEntityRequest } from '@models/authorization';
import { UserListItemParams, UserListItemResponse, UserResponse, UserStats } from '@models/user';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsersService {
  private readonly userUrl = `${environment.bffUrl}/users`;
  constructor(private httpClient: HttpClient) {}

  getUsers(query: Partial<UserListItemParams>): Observable<UserListItemResponse[]> {
    return this.httpClient.get<UserListItemResponse[]>(this.userUrl, { params: this.convertToHttpParams(query) });
  }

  getUser(id: string): Observable<UserResponse> {
    return this.httpClient.get<UserResponse>(`${this.userUrl}/${id}`);
  }

  getUserRoles(id: string, partnerId: string, filter: RoleType): Observable<GroupedRoles[]> {
    return this.httpClient.get<GroupedRoles[]>(`${this.userUrl}/user/${id}/partner/${partnerId}/roles`, {
      params: { type: filter },
    });
  }

  getUserStats(id: string): Observable<UserStats> {
    return this.httpClient.get<UserStats>(`${this.userUrl}/${id}/statistics`);
  }

  getUserPermissions(id: string, partnerId: string): Observable<Permission[]> {
    return this.httpClient.get<Permission[]>(`${this.userUrl}/user/${id}/partner/${partnerId}/permissions`);
  }

  setUserRoles(id: string, partnerId: string, roles: UpdateRoleForEntityRequest[], filter: RoleType): Observable<GroupedRoles[]> {
    return this.httpClient.patch<GroupedRoles[]>(`${this.userUrl}/user/${id}/partner/${partnerId}/roles`, roles, {
      params: { type: filter },
    });
  }

  removeAdditionalPartner(userId: string, partnerId: string): Observable<UserResponse> {
    return this.httpClient.post<UserResponse>(`${this.userUrl}/${userId}/remove-additional-partner`, { partnerId });
  }

  addAdditionalPartner(userId: string, partnerId: string): Observable<UserResponse> {
    return this.httpClient.post<UserResponse>(`${this.userUrl}/${userId}/add-additional-partner`, { partnerId });
  }

  blockUnblockUser(id: string, block: boolean): Observable<UserResponse> {
    return this.httpClient.post<UserResponse>(`${this.userUrl}/${id}/${block ? 'block' : 'unblock'}`, null);
  }

  markForDeletion(id: string): Observable<UserResponse> {
    return this.httpClient.post<UserResponse>(`${this.userUrl}/${id}/mark-for-deletion`, null);
  }

  updateCrmContactId(userId: string, crmContactId: string): Observable<UserResponse> {
    return this.httpClient.post<UserResponse>(`${this.userUrl}/${userId}/update-crm-contact-id`, { crmContactId });
  }

  resetUserTwoFa(id: string): Observable<UserResponse> {
    return this.httpClient.post<UserResponse>(`${this.userUrl}/${id}/reset-2fa`, null);
  }

  private convertToHttpParams(params: Params): HttpParams {
    let httpParams = new HttpParams();

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          httpParams = httpParams.append(key, item ? item.toString() : '');
        });
      } else {
        httpParams = httpParams.append(key, value ? value.toString() : '');
      }
    });

    return httpParams;
  }
}
