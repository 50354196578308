import { FileListItem, FileResponse } from '@models/file-management/file/file.model';
import { createReducer, on } from '@ngrx/store';
import { predicateForSorting, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './files.actions';

export interface State {
  isLoading: boolean;
  fileCreateLoading: boolean;
  files: FileListItem[];

  selectedFile: FileResponse | null;
  selectedFileLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  fileCreateLoading: false,
  files: [],

  selectedFile: null,
  selectedFileLoading: false,
};

export const filesReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { files }) => ({
    ...state,
    files: sortAlphabetically(files, predicateForSorting('fileName')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.addFile, state => ({ ...state, fileCreateLoading: true, isLoading: true })),
  on(fromActions.addFileComplete, (state, { fileRes }) => ({
    ...state,
    isLoading: false,
    fileCreateLoading: false,
    files: sortAlphabetically([...state.files, fileRes], predicateForSorting('fileName')),
  })),
  on(fromActions.uploadFile, state => ({ ...state })),
  on(fromActions.addFileError, state => ({ ...state, isLoading: false, fileCreateLoading: false })),

  on(fromActions.selectFile, state => ({ ...state, selectedFileLoading: true })),
  on(fromActions.selectFileComplete, (state, { fileRes }) => ({ ...state, selectedFileLoading: false, selectedFile: fileRes })),
  on(fromActions.selectFileError, state => ({ ...state, selectedFileLoading: false })),

  on(fromActions.deleteSelectedFile, state => ({ ...state, selectedFileLoading: true })),
  on(fromActions.deleteSelectedFileComplete, state => ({
    ...state,
    selectedFileLoading: false,
    selectedFile: null,
    selectedOwner: null,
    owners: [],
    subscribers: [],
  })),
  on(fromActions.deleteSelectedFileError, state => ({
    ...state,
    selectedFileLoading: false,
  })),
);
