import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { requestsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './requests.actions';
import { RequestsService } from './requests.service';

@Injectable()
export class RequestsEffects {
  onGetRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(({ isActive }) =>
        this.requestsService.getAll(isActive ? isActive : undefined).pipe(
          map(requests => fromActions.getAllComplete({ requests })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onSelectRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectRequest),
      switchMap(({ id }) =>
        this.store.pipe(
          select(requestsSelectors.selectRequests),
          take(1),
          switchMap(requests => {
            if (requests.length) {
              const request = requests.find(p => p.id === id)!;
              return of(fromActions.selectRequestComplete({ request }));
            } else {
              return this.requestsService.get(id).pipe(
                map(request => fromActions.selectRequestComplete({ request })),
                catchError(err => of(fromActions.selectRequestError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onGoToRequestActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToRequestActions),
        tap(({ id }) => this.router.navigate(['requests', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['requests'])),
      map(() => fromActions.resetState({ selectedRequest: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private requestsService: RequestsService,
  ) {}
}
