import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { downloadBlobUrlFileToBrowser } from '@highlander/common/helpers/file.helpers';
import { AssessmentToggleOptions } from '@models/products-and-orders/assessments';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as fromActions from './assessments.actions';
import { AssessmentsService } from './assessments.service';

@Injectable()
export class AssessmentsEffects {
  onGetAssessmentsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAssessmentsList),
      switchMap(({ toggle }) =>
        this.assessmentsService
          .getAll(toggle === AssessmentToggleOptions.Open ? ['Saved', 'Submitted', 'Analyzing', 'OnHold', 'Completed'] : [])
          .pipe(
            map(assessments => fromActions.getAssessmentsListComplete({ assessments })),
            catchError(({ error }) => of(fromActions.getAssessmentsListError({ error }))),
          ),
      ),
    ),
  );
  onSelectAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectAssessment),
      switchMap(({ id }) =>
        this.assessmentsService.get(id).pipe(
          map(assessment => fromActions.selectAssessmentComplete({ assessment })),
          catchError(({ error }) => of(fromActions.selectAssessmentError({ error }))),
        ),
      ),
    ),
  );

  onDownloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadFile),
      switchMap(({ id, fileId }) =>
        this.assessmentsService.getDownloadUrl(id, fileId).pipe(
          map(res => fromActions.downloadFileComplete({ url: res.URL })),
          catchError(({ error }) => of(fromActions.downloadFileError({ error }))),
        ),
      ),
    ),
  );

  onDownloadSelectedFileComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.downloadFileComplete),
        tap(({ url }) => {
          downloadBlobUrlFileToBrowser(url);
        }),
      ),
    { dispatch: false },
  );

  onGoToAssessmentActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToAssessmentActions),
        tap(({ id }) => this.router.navigate(['assessments', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToAssessmentsListPage),
      tap(() => this.router.navigate(['assessments'])),
      map(() => fromActions.resetState({ selectedAssessment: null })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly assessmentsService: AssessmentsService,
    private readonly router: Router,
  ) {}
}
