import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateTemplateAdditionalReceiverRequest,
  CreateTemplateMappingRequest,
  CreateTemplateRequest,
  Template,
  TemplateAdditionalReceiver,
  TemplateListItem,
  TemplateMapping,
  TemplateMappingContent,
  UpdateTemplateAdditionalReceiverRequest,
  UpdateTemplateMappingContentRequest,
  UpdateTemplateMappingRequest,
  UpdateTemplateRequest,
} from '@models/notifications/templates';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class TemplatesService {
  private readonly baseUrl = `${environment.bffUrl}/templates`;
  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<TemplateListItem[]> {
    return this.httpClient.get<TemplateListItem[]>(`${this.baseUrl}`);
  }

  getOne(templateId: string): Observable<Template> {
    return this.httpClient.get<Template>(`${this.baseUrl}/${templateId}`);
  }

  create(createReq: CreateTemplateRequest): Observable<Template> {
    return this.httpClient.post<Template>(`${this.baseUrl}`, createReq);
  }

  update(templateId: string, updateReq: UpdateTemplateRequest): Observable<Template> {
    return this.httpClient.put<Template>(`${this.baseUrl}/${templateId}`, updateReq);
  }

  delete(templateId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${templateId}`);
  }

  getAllAdditionalReceivers(templateId: string): Observable<TemplateAdditionalReceiver[]> {
    return this.httpClient.get<TemplateAdditionalReceiver[]>(`${this.baseUrl}/${templateId}/receivers`);
  }

  getOneAdditionalReceiver(templateId: string, additionalReceiverId: string): Observable<TemplateAdditionalReceiver> {
    return this.httpClient.get<TemplateAdditionalReceiver>(`${this.baseUrl}/${templateId}/receivers/${additionalReceiverId}`);
  }

  createAdditionalReceiver(templateId: string, createReq: CreateTemplateAdditionalReceiverRequest): Observable<TemplateAdditionalReceiver> {
    return this.httpClient.post<TemplateAdditionalReceiver>(`${this.baseUrl}/${templateId}/receivers`, createReq);
  }

  updateAdditionalReceiver(
    templateId: string,
    additionalReceiverId: string,
    updateReq: UpdateTemplateAdditionalReceiverRequest,
  ): Observable<TemplateAdditionalReceiver> {
    return this.httpClient.put<TemplateAdditionalReceiver>(`${this.baseUrl}/${templateId}/receivers/${additionalReceiverId}`, updateReq);
  }

  deleteAdditionalReceiver(templateId: string, additionalReceiverId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${templateId}/receivers/${additionalReceiverId}`);
  }

  getAllMappings(templateId: string): Observable<TemplateMapping[]> {
    return this.httpClient.get<TemplateMapping[]>(`${this.baseUrl}/${templateId}/mappings`);
  }

  getOneMapping(templateId: string, mappingId: string): Observable<TemplateMapping> {
    return this.httpClient.get<TemplateMapping>(`${this.baseUrl}/${templateId}/mappings/${mappingId}`);
  }

  createMapping(templateId: string, createReq: CreateTemplateMappingRequest): Observable<TemplateMapping> {
    return this.httpClient.post<TemplateMapping>(`${this.baseUrl}/${templateId}/mappings`, createReq);
  }

  updateMapping(templateId: string, mappingId: string, updateReq: UpdateTemplateMappingRequest): Observable<TemplateMapping> {
    return this.httpClient.put<TemplateMapping>(`${this.baseUrl}/${templateId}/mappings/${mappingId}`, updateReq);
  }

  deleteMapping(templateId: string, mappingId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${templateId}/mappings/${mappingId}`);
  }

  getAllMappingContents(templateId: string, mappingId: string): Observable<TemplateMappingContent[]> {
    return this.httpClient.get<TemplateMappingContent[]>(`${this.baseUrl}/${templateId}/mappings/${mappingId}/content`);
  }

  getOneMappingContent(templateId: string, mappingId: string, tagName: string): Observable<TemplateMappingContent> {
    return this.httpClient.get<TemplateMappingContent>(`${this.baseUrl}/${templateId}/mappings/${mappingId}/content/${tagName}`);
  }

  updateMappingContent(
    templateId: string,
    mappingId: string,
    tagName: string,
    updateReq: UpdateTemplateMappingContentRequest,
  ): Observable<TemplateMappingContent> {
    return this.httpClient.put<TemplateMappingContent>(`${this.baseUrl}/${templateId}/mappings/${mappingId}/content/${tagName}`, updateReq);
  }

  deleteMappingContent(templateId: string, mappingId: string, tagName: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${templateId}/mappings/${mappingId}/content/${tagName}`);
  }

  mappingActivation(templateId: string, mappingId: string, activate: boolean): Observable<TemplateMapping> {
    return this.httpClient.post<TemplateMapping>(
      `${this.baseUrl}/${templateId}/mappings/${mappingId}/${activate ? 'activate' : 'deactivate'}`,
      undefined,
    );
  }
}
