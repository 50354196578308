import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductRequest } from '@models/products-and-orders/requests';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class RequestsService {
  private readonly url = `${environment.bffUrl}/requests`;
  constructor(private httpClient: HttpClient) {}

  getAll(isActive?: boolean): Observable<ProductRequest[]> {
    return this.httpClient.get<ProductRequest[]>(this.url, { params: isActive !== undefined ? { isActive } : {} });
  }

  get(id: string): Observable<ProductRequest> {
    return this.httpClient.get<ProductRequest>(`${this.url}/${id}`);
  }
}
