import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { commentsActions, commonDataActions, commonDataSelectors } from '@appState';
import { CommentsQueryParams } from '@models/index';
import { select, Store } from '@ngrx/store';
import {
  Choice,
  CuiButtonsModule,
  CuiCardModule,
  CuiDatepickerComponent,
  CuiInputComponent,
  CuiSimpleSelectComponent,
  CuiTableModule,
  formatToISODate,
  formatToISODateEndOfDay,
} from 'cui-components';
import { map, Observable } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';

@Component({
  imports: [
    CommonModule,
    CuiTableModule,
    RouterModule,
    CuiCardModule,
    FormsModule,
    ReactiveFormsModule,
    CuiDatepickerComponent,
    CuiButtonsModule,
    CuiSimpleSelectComponent,
    CuiInputComponent,
  ],
  template: `
    <cui-card>
      <cui-card-header [showCloseBtn]="true" (close)="onCancel()">
        <h2>Comments filter</h2>
      </cui-card-header>
      <cui-card-body>
        <form [formGroup]="form">
          <div class="date-fields">
            <cui-date-picker
              [label]="'Start Date'"
              formControlName="createdFromDateTime"
              [required]="true"
              [maxDate]="createdToControl?.value"
            ></cui-date-picker>
            <span class="flex-spacer"></span>
            <cui-date-picker
              [label]="'End Date'"
              formControlName="createdToDateTime"
              [required]="true"
              [minDate]="createdFromControl?.value"
              [maxDate]="todaysDate"
            ></cui-date-picker>
            <cui-simple-select
              formControlName="regionId"
              [choices]="(regions$ | async)!"
              [label]="'Region'"
              [hint]="'If changed then currently selected comment will be closed.'"
            ></cui-simple-select>
            <cui-input
              formControlName="articleId"
              [label]="'Article id'"
              [hint]="'If applied then currently selected comment will be closed.'"
            ></cui-input>
          </div>
        </form>
      </cui-card-body>
      <cui-card-footer>
        <cui-button (clicked)="onCancel()">Cancel</cui-button>
        <cui-button-cta [disabled]="!form.valid" (clicked)="onConfirm()">Submit</cui-button-cta>
      </cui-card-footer>
    </cui-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsFiltersComponent implements OnInit {
  regions$: Observable<Choice[]> = this.store.pipe(
    select(commonDataSelectors.selectRegions),
    map(regions => {
      if (!regions.length) {
        this.store.dispatch(commonDataActions.getRegions());
        return [];
      } else {
        const DEFAULT_CHOICE: Choice = { value: '', text: '<Any>' };
        return [DEFAULT_CHOICE, ...regions.map(region => ({ value: region.id, text: region.details.name }))];
      }
    }),
  );

  form!: FormGroup;
  todaysDate = new Date();
  initialFilterSettings?: CommentsQueryParams;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CommentsQueryParams,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CommentsFiltersComponent>,
  ) {}

  ngOnInit(): void {
    const filterSettings = localStorage.getItem('commentsFilter');
    if (filterSettings) {
      this.initialFilterSettings = JSON.parse(filterSettings) as CommentsQueryParams;
      this.form = this.fb.group({
        createdFromDateTime: [this.initialFilterSettings.createdFromDateTime, [Validators.required]],
        createdToDateTime: [this.initialFilterSettings.createdToDateTime, [Validators.required]],
        regionId: [this.initialFilterSettings.regionId],
        articleId: [this.initialFilterSettings.articleId],
      });
    }
  }

  get createdFromControl(): AbstractControl | null {
    return this.form.get('createdFromDateTime');
  }

  get createdToControl(): AbstractControl | null {
    return this.form.get('createdToDateTime');
  }

  onConfirm(): void {
    if (this.form.valid) {
      const commentsQuery: CommentsQueryParams = {
        ...this.form.value,
        createdFromDateTime: formatToISODate(new Date(this.form.value.createdFromDateTime)),
        createdToDateTime: formatToISODateEndOfDay(new Date(this.form.value.createdToDateTime)),
      };
      const hasDifferentArticleId = !!commentsQuery.articleId && commentsQuery.articleId !== this.initialFilterSettings?.articleId;
      const hasDifferentRegionId = !!commentsQuery.regionId && commentsQuery.regionId !== this.initialFilterSettings?.regionId;
      this.store.dispatch(commentsActions.submitFilterModal({ commentsQuery }));
      this.dialogRef.close({ commentsQuery, routeToList: hasDifferentArticleId || hasDifferentRegionId });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
