import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.requests);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectRequests = createSelector(selectModuleState, state => state.requests);

export const selectSelectedRequest = createSelector(selectModuleState, state => state.selectedRequest);
export const selectSelectedRequestId = createSelector(selectModuleState, state => state.selectedRequest && state.selectedRequest.id);
export const selectSelectedRequestLoading = createSelector(selectModuleState, state => state.selectedRequestLoading);

export const selectSelectedExternalProductId = createSelector(
  selectModuleState,
  state => state.selectedRequest && state.selectedRequest.product && state.selectedRequest.product.externalId,
);
