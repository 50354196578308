import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.user);

export const selectIsLoadingUser = createSelector(selectModuleState, state => state.isLoadingUser);
export const selectIsLoadingUserList = createSelector(selectModuleState, state => state.isLoadingUserList);
export const selectIsChangingAdditionalPartnerForUser = createSelector(
  selectModuleState,
  state => state.changingAdditionalPartnerListInProgress,
);
export const selectUserList = createSelector(selectModuleState, users => users.users);
export const selectCurrentUserId = createSelector(selectModuleState, state => state.selectedUser?.id ?? null);
export const selectCurrentUser = createSelector(selectModuleState, state => state.selectedUser);

export const selectCurrentUserRoles = createSelector(selectModuleState, state => state.selectedUserRoles);
export const selectCurrentUserRolesLoading = createSelector(selectModuleState, state => state.isUserRolesLoading);

export const selectCurrentUserStats = createSelector(selectModuleState, state => state.selectedUserStats);
export const selectCurrentUserStatsLoading = createSelector(selectModuleState, state => state.isUserStatsLoading);
