import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filesSelectors } from '@appState';
import { downloadBlobUrlFileToBrowser } from '@highlander/common/helpers/file.helpers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BlobStorageService, isNotNullOrUndefined } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './files.actions';
import { FilesService } from './files.service';

@Injectable()
export class FilesEffects {
  onGetFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.filesService.getAll().pipe(
          map(files => fromActions.getAllComplete({ files })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onAddFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addFile),
      switchMap(({ fileReq, file }) =>
        this.filesService.add(fileReq).pipe(
          map(response => fromActions.uploadFile({ response, file })),
          catchError(err => of(fromActions.addFileError({ err }))),
        ),
      ),
    ),
  );

  onAddFileComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addFileComplete),
        tap(({ fileRes }) => {
          return this.router.navigate(['file-management', 'files', fileRes.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onUploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.uploadFile),
      switchMap(({ response, file }) =>
        this.blobStorageService.uploadFile(response.uploadURL, file).pipe(
          map(() => fromActions.activateFile({ id: response.id })),
          catchError(err => of(fromActions.addFileError({ err }))),
        ),
      ),
    ),
  );

  onActivateFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateFile),
      switchMap(({ id }) =>
        this.filesService.activate(id).pipe(
          map(fileRes => fromActions.addFileComplete({ fileRes })),
          catchError(err => of(fromActions.addFileError({ err }))),
        ),
      ),
    ),
  );

  onDownloadSelectedFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadSelectedFile),
      switchMap(() =>
        this.store.pipe(
          select(filesSelectors.selectSelectedFileId),
          take(1),
          switchMap(id =>
            this.filesService.getDownloadUrl(id!).pipe(
              map(res => fromActions.downloadSelectedFileComplete({ url: res.URL })),
              catchError(() => of(fromActions.downloadSelectedFileError())),
            ),
          ),
        ),
      ),
    ),
  );

  onDownloadSelectedFileComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.downloadSelectedFileComplete),
        tap(({ url }) => {
          downloadBlobUrlFileToBrowser(url);
        }),
      ),
    { dispatch: false },
  );

  onSelectFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectFile),
      switchMap(({ id }) =>
        this.filesService.get(id).pipe(
          map(fileRes => fromActions.selectFileComplete({ fileRes })),
          catchError(err => of(fromActions.selectFileError({ err }))),
        ),
      ),
    ),
  );

  onDeleteFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedFile),
      switchMap(() =>
        this.store.pipe(
          select(filesSelectors.selectSelectedFile),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(file =>
            this.filesService.delete(file.id).pipe(
              map(() => fromActions.deleteSelectedFileComplete()),
              catchError(err => of(fromActions.deleteSelectedFileError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteFileComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedFileComplete),
      tap(() => this.router.navigate(['file-management', 'files'])),
      map(() => fromActions.getAll()),
    ),
  );

  onGoToFileActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToFileActions),
        tap(({ id }) => this.router.navigate(['file-management', 'files', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['file-management', 'files'])),
      map(() => fromActions.resetState({ selectedFile: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private filesService: FilesService,
    private blobStorageService: BlobStorageService,
  ) {}
}
