import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GroupListItemResponse,
  GroupResponse,
  NewGroupRequest,
  UpdateGroupRequest,
} from '@models/notifications/receiver-groups/receiver-groups.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReceiverGroupsService {
  private readonly url = `${environment.bffUrl}/groups`;
  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<GroupListItemResponse[]> {
    return this.httpClient.get<GroupListItemResponse[]>(this.url);
  }

  get(id: string): Observable<GroupResponse> {
    return this.httpClient.get<GroupResponse>(`${this.url}/${id}`);
  }

  add(group: NewGroupRequest): Observable<GroupResponse> {
    return this.httpClient.post<GroupResponse>(this.url, group);
  }

  update(id: string, updateGroup: UpdateGroupRequest): Observable<GroupResponse> {
    return this.httpClient.put<GroupResponse>(`${this.url}/${id}`, updateGroup);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }
}
