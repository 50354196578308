import { HttpErrorResponse } from '@angular/common/http';
import { CreateFileRequest, CreateFileResponse, FileResponse } from '@models/index';
import { Product, ProductsListItem, ProductUpdateRequest } from '@models/products-and-orders/products';
import { createAction, props } from '@ngrx/store';
import { State } from './products.reducer';

const NAMESPACE = '[Products]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ products: ProductsListItem[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const updateProduct = createAction(`${NAMESPACE} UpdateProduct`, props<{ id: string; product: ProductUpdateRequest }>());
export const updateProductComplete = createAction(`${NAMESPACE} UpdateProductComplete`, props<{ product: Product }>());
export const updateProductError = createAction(`${NAMESPACE} UpdateProductError`, props<{ err: HttpErrorResponse }>());

export const activateProduct = createAction(`${NAMESPACE} ActivateProduct`, props<{ id: string }>());
export const activateProductComplete = createAction(`${NAMESPACE} ActivateProductComplete`, props<{ product: Product }>());
export const activateProductError = createAction(`${NAMESPACE} ActivateProductError`, props<{ err: HttpErrorResponse }>());

export const deactivateProduct = createAction(`${NAMESPACE} DeactivateProduct`, props<{ id: string }>());
export const deactivateProductComplete = createAction(`${NAMESPACE} DeactivateProductComplete`, props<{ product: Product }>());
export const deactivateProductError = createAction(`${NAMESPACE} DeactivateProductError`, props<{ err: HttpErrorResponse }>());

export const selectProduct = createAction(`${NAMESPACE} SelectProduct`, props<{ id: string }>());
export const selectProductComplete = createAction(`${NAMESPACE} SelectProductComplete`, props<{ product: Product }>());
export const selectProductError = createAction(`${NAMESPACE} SelectProductError`, props<{ err: HttpErrorResponse }>());

export const getAllGenericFiles = createAction(`${NAMESPACE} GetAllGenericFiles`, props<{ id: string }>());
export const getAllGenericFilesComplete = createAction(`${NAMESPACE} GetAllGenericFilesComplete`, props<{ files: FileResponse[] }>());
export const getAllGenericFilesError = createAction(`${NAMESPACE} GetAllGenericFilesError`, props<{ err: HttpErrorResponse }>());

export const selectGenericFile = createAction(`${NAMESPACE} SelectGenericFile`, props<{ id: string; fileId: string }>());
export const selectGenericFileComplete = createAction(`${NAMESPACE} SelectGenericFileComplete`, props<{ file: FileResponse }>());
export const selectGenericFileError = createAction(`${NAMESPACE} SelectGenericFileError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedGenericFile = createAction(
  `${NAMESPACE} DeleteSelectedGenericFile`,
  props<{ productId: string; fileId: string }>(),
);
export const deleteSelectedGenericFileComplete = createAction(`${NAMESPACE} DeleteSelectedGenericFileComplete`, props<{ id: string }>());
export const deleteSelectedGenericFileError = createAction(
  `${NAMESPACE} DeleteSelectedGenericFileError`,
  props<{ err: HttpErrorResponse }>(),
);

export const addGenericFile = createAction(`${NAMESPACE} AddGenericFile`, props<{ fileReq: CreateFileRequest; file: File }>());
export const addGenericFileComplete = createAction(`${NAMESPACE} AddGenericFileComplete`, props<{ fileRes: FileResponse }>());
export const addGenericFileError = createAction(`${NAMESPACE} AddGenericFileError`, props<{ err: HttpErrorResponse }>());

export const uploadGenericFile = createAction(`${NAMESPACE} UploadGenericFile`, props<{ response: CreateFileResponse; file: File }>());

export const activateGenericFile = createAction(`${NAMESPACE} ActivateGenericFile`, props<{ id: string }>());

export const downloadSelectedGenericFile = createAction(`${NAMESPACE} DownloadSelectedGenericFile`);
export const downloadSelectedGenericFileComplete = createAction(
  `${NAMESPACE} DownloadSelectedGenericFileComplete`,
  props<{ url: string }>(),
);
export const downloadSelectedGenericFileError = createAction(`${NAMESPACE} DownloadSelectedGenericFileError`);

export const goToProductActions = createAction(`${NAMESPACE} GoToProductActions`, props<{ id: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const goToGenericFileActions = createAction(`${NAMESPACE} GoToGenericFileActions`, props<{ fileId: string }>());
export const goToGenericFileListPage = createAction(`${NAMESPACE} GoToGenericFileListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
