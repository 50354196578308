import { HttpErrorResponse } from '@angular/common/http';
import {
  CombinedEmail,
  GroupListItemResponse,
  GroupResponse,
  NewGroupRequest,
  UpdateGroupRequest,
} from '@models/notifications/receiver-groups/receiver-groups.model';
import { createAction, props } from '@ngrx/store';
import { State } from './receiver-groups.reducer';

const NAMESPACE = '[Notifications Receiver Groups]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ groups: GroupListItemResponse[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addGroup = createAction(`${NAMESPACE} AddGroup`, props<{ group: NewGroupRequest }>());
export const addGroupComplete = createAction(`${NAMESPACE} AddGroupComplete`, props<{ group: GroupResponse }>());
export const addGroupError = createAction(`${NAMESPACE} AddGroupError`, props<{ err: HttpErrorResponse }>());

export const updateGroup = createAction(`${NAMESPACE} UpdateGroup`, props<{ id: string; updatedGroup: UpdateGroupRequest }>());
export const updateGroupComplete = createAction(`${NAMESPACE} UpdateGroupComplete`, props<{ group: GroupResponse }>());
export const updateGroupError = createAction(`${NAMESPACE} UpdateGroupError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedGroup = createAction(`${NAMESPACE} DeleteSelectedGroup`);
export const deleteSelectedGroupComplete = createAction(`${NAMESPACE} DeleteSelectedGroupComplete`);
export const deleteSelectedGroupError = createAction(`${NAMESPACE} DeleteSelectedGroupError`, props<{ err: HttpErrorResponse }>());

export const selectGroup = createAction(`${NAMESPACE} SelectGroup`, props<{ id: string }>());
export const selectGroupComplete = createAction(`${NAMESPACE} SelectGroupComplete`, props<{ group: GroupResponse }>());
export const selectGroupError = createAction(`${NAMESPACE} SelectGroupError`, props<{ err: HttpErrorResponse }>());

export const addCombinedEmail = createAction(
  `${NAMESPACE} AddCombinedEmail`,
  props<{ id: string; updatedGroup: UpdateGroupRequest; combinedEmail: CombinedEmail }>(),
);
export const addCombinedEmailComplete = createAction(
  `${NAMESPACE} AddCombinedEmailComplete`,
  props<{ id: string; group: GroupResponse; combinedEmail: CombinedEmail }>(),
);
export const addCombinedEmailError = createAction(`${NAMESPACE} AddCombinedEmailError`, props<{ err: HttpErrorResponse }>());

export const updateCombinedEmail = createAction(
  `${NAMESPACE} UpdateCombinedEmail`,
  props<{ id: string; updatedGroup: UpdateGroupRequest; combinedEmail: CombinedEmail }>(),
);
export const updateCombinedEmailComplete = createAction(
  `${NAMESPACE} UpdateCombinedEmailComplete`,
  props<{ id: string; combinedEmail: CombinedEmail; group: GroupResponse }>(),
);
export const updateCombinedEmailError = createAction(`${NAMESPACE} UpdateCombinedEmailError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedCombinedEmail = createAction(
  `${NAMESPACE} DeleteSelectedCombinedEmail`,
  props<{ id: string; updatedGroup: UpdateGroupRequest }>(),
);
export const deleteSelectedCombinedEmailComplete = createAction(
  `${NAMESPACE} DeleteSelectedCombinedEmailComplete`,
  props<{ id: string; group: GroupResponse }>(),
);
export const deleteSelectedCombinedEmailError = createAction(
  `${NAMESPACE} DeleteSelectedCombinedEmailError`,
  props<{ err: HttpErrorResponse }>(),
);

export const selectCombinedEmail = createAction(`${NAMESPACE} SelectCombinedEmail`, props<{ id: string; email: string }>());
export const selectCombinedEmailComplete = createAction(
  `${NAMESPACE} SelectCombinedEmailComplete`,
  props<{ combinedEmail: CombinedEmail }>(),
);
export const selectCombinedEmailError = createAction(`${NAMESPACE} SelectCombinedEmailError`, props<{ err: HttpErrorResponse }>());

export const goToGroupActions = createAction(`${NAMESPACE} GoToGroupActions`, props<{ id: string }>());
export const goToGroupsListPage = createAction(`${NAMESPACE} GoToGroupsListPage`);
export const goToCombinedEmailActions = createAction(`${NAMESPACE} GoToCombinedEmailActions`, props<{ id: string; email: string }>());
export const goToCombinedEmailsListPage = createAction(`${NAMESPACE} GoToCombinedEmailsListPage`, props<{ id: string }>());

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
