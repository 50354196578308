import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddRoleGroupRequest, GroupedRoles, RoleGroup, RoleType, UpdateRoleGroupRequest } from '@models/authorization';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class RoleGroupsService {
  constructor(private httpClient: HttpClient) {}

  private readonly url = `${environment.bffUrl}/authorization/role-groups`;

  getRoleGroups(): Observable<RoleGroup[]> {
    return this.httpClient.get<RoleGroup[]>(this.url);
  }

  getGroupedRoles(filter: RoleType = 'Regular'): Observable<GroupedRoles[]> {
    return this.httpClient.get<GroupedRoles[]>(`${this.url}/grouped`, { params: { type: filter } });
  }

  getRoleGroupRoles(roleGroupId: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.url}/${roleGroupId}/roles`);
  }

  addNewRoleGroup(roleGroup: AddRoleGroupRequest): Observable<RoleGroup> {
    return this.httpClient.post<RoleGroup>(this.url, roleGroup);
  }

  updateRoleGroup(id: string, updateRoleGroup: UpdateRoleGroupRequest): Observable<RoleGroup> {
    return this.httpClient.put<RoleGroup>(`${this.url}/${id}`, updateRoleGroup);
  }

  deleteRoleGroup(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }
}
