import { HttpErrorResponse } from '@angular/common/http';
import {
  CreateTemplateAdditionalReceiverRequest,
  CreateTemplateMappingRequest,
  CreateTemplateRequest,
  Template,
  TemplateAdditionalReceiver,
  TemplateListItem,
  TemplateMapping,
  TemplateMappingContent,
  UpdateTemplateAdditionalReceiverRequest,
  UpdateTemplateMappingContentRequest,
  UpdateTemplateMappingRequest,
  UpdateTemplateRequest,
} from '@models/notifications/templates';
import { createAction, props } from '@ngrx/store';
import { State } from './templates.reducer';

const NAMESPACE = '[Templates]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ templates: TemplateListItem[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addTemplate = createAction(`${NAMESPACE} AddTemplate`, props<{ template: CreateTemplateRequest }>());
export const addTemplateComplete = createAction(`${NAMESPACE} AddTemplateComplete`, props<{ template: Template }>());
export const addTemplateError = createAction(`${NAMESPACE} AddTemplateError`, props<{ err: HttpErrorResponse }>());

export const updateTemplate = createAction(`${NAMESPACE} UpdateTemplate`, props<{ id: string; template: UpdateTemplateRequest }>());
export const updateTemplateComplete = createAction(`${NAMESPACE} UpdateTemplateComplete`, props<{ template: Template }>());
export const updateTemplateError = createAction(`${NAMESPACE} UpdateTemplateError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedTemplate = createAction(`${NAMESPACE} DeleteSelectedTemplate`);
export const deleteSelectedTemplateComplete = createAction(`${NAMESPACE} DeleteSelectedTemplateComplete`);
export const deleteSelectedTemplateError = createAction(`${NAMESPACE} DeleteSelectedTemplateError`, props<{ err: HttpErrorResponse }>());

export const selectTemplate = createAction(`${NAMESPACE} SelectTemplate`, props<{ id: string }>());
export const selectTemplateComplete = createAction(`${NAMESPACE} SelectTemplateComplete`, props<{ template: Template }>());
export const selectTemplateError = createAction(`${NAMESPACE} SelectTemplateError`, props<{ err: HttpErrorResponse }>());

export const goToTemplateActions = createAction(`${NAMESPACE} GoToTemplateActions`, props<{ templateId: string }>());
export const goToTemplateListPage = createAction(`${NAMESPACE} GoToTemplateListPage`);

export const getAdditionalReceivers = createAction(`${NAMESPACE} GetAdditionalReceivers`, props<{ templateId: string }>());
export const getAdditionalReceiversComplete = createAction(
  `${NAMESPACE} GetAdditionalReceiversComplete`,
  props<{ additionalReceivers: TemplateAdditionalReceiver[] }>(),
);
export const getAdditionalReceiversError = createAction(`${NAMESPACE} GetAdditionalReceiversError`, props<{ err: HttpErrorResponse }>());

export const addAdditionalReceiver = createAction(
  `${NAMESPACE} AddAdditionalReceiver`,
  props<{ templateId: string; additionalReceiver: CreateTemplateAdditionalReceiverRequest }>(),
);
export const addAdditionalReceiverComplete = createAction(
  `${NAMESPACE} AddAdditionalReceiverComplete`,
  props<{ additionalReceiver: TemplateAdditionalReceiver }>(),
);
export const addAdditionalReceiverError = createAction(`${NAMESPACE} AddAdditionalReceiverError`, props<{ err: HttpErrorResponse }>());

export const updateAdditionalReceiver = createAction(
  `${NAMESPACE} updateAdditionalReceiver`,
  props<{ templateId: string; additionalReceiverId: string; additionalReceiver: UpdateTemplateAdditionalReceiverRequest }>(),
);
export const updateAdditionalReceiverComplete = createAction(
  `${NAMESPACE} updateAdditionalReceiverComplete`,
  props<{ additionalReceiver: TemplateAdditionalReceiver }>(),
);
export const updateAdditionalReceiverError = createAction(
  `${NAMESPACE} updateAdditionalReceiverError`,
  props<{ err: HttpErrorResponse }>(),
);

export const deleteSelectedAdditionalReceiver = createAction(
  `${NAMESPACE} DeleteAdditionalReceiver`,
  props<{ templateId: string; additionalReceiverId: string }>(),
);
export const deleteSelectedAdditionalReceiverComplete = createAction(
  `${NAMESPACE} DeleteAdditionalReceiverComplete`,
  props<{ templateId: string }>(),
);
export const deleteSelectedAdditionalReceiverError = createAction(
  `${NAMESPACE} DeleteAdditionalReceiverError`,
  props<{ err: HttpErrorResponse }>(),
);

export const selectAdditionalReceiver = createAction(
  `${NAMESPACE} SelectAdditionalReceiver`,
  props<{ templateId: string; additionalReceiverId: string }>(),
);
export const selectAdditionalReceiverComplete = createAction(
  `${NAMESPACE} SelectAdditionalReceiverComplete`,
  props<{ additionalReceiver: TemplateAdditionalReceiver }>(),
);
export const selectAdditionalReceiverError = createAction(
  `${NAMESPACE} SelectAdditionalReceiverError`,
  props<{ err: HttpErrorResponse }>(),
);

export const goToAdditionalReceiverActions = createAction(
  `${NAMESPACE} GoToAdditionalReceiverActions`,
  props<{ templateId: string; additionalReceiverId: string }>(),
);
export const goToAdditionalReceiverListPage = createAction(`${NAMESPACE} GoToAdditionalReceiverListPage`, props<{ templateId: string }>());

export const getMappings = createAction(`${NAMESPACE} GetMappings`, props<{ templateId: string }>());
export const getMappingsComplete = createAction(`${NAMESPACE} GetMappingsComplete`, props<{ mappings: TemplateMapping[] }>());
export const getMappingsError = createAction(`${NAMESPACE} GetMappingsError`, props<{ err: HttpErrorResponse }>());

export const addMapping = createAction(`${NAMESPACE} AddMapping`, props<{ templateId: string; mapping: CreateTemplateMappingRequest }>());
export const addMappingComplete = createAction(`${NAMESPACE} AddMappingComplete`, props<{ mapping: TemplateMapping }>());
export const addMappingError = createAction(`${NAMESPACE} AddMappingError`, props<{ err: HttpErrorResponse }>());

export const updateMapping = createAction(
  `${NAMESPACE} updateMapping`,
  props<{ templateId: string; mappingId: string; mapping: UpdateTemplateMappingRequest }>(),
);
export const updateMappingComplete = createAction(`${NAMESPACE} updateMappingComplete`, props<{ mapping: TemplateMapping }>());
export const updateMappingError = createAction(`${NAMESPACE} updateMappingError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedMapping = createAction(`${NAMESPACE} DeleteMapping`, props<{ templateId: string; mappingId: string }>());
export const deleteSelectedMappingComplete = createAction(`${NAMESPACE} DeleteMappingComplete`, props<{ templateId: string }>());
export const deleteSelectedMappingError = createAction(`${NAMESPACE} DeleteMappingError`, props<{ err: HttpErrorResponse }>());

export const activationOfSelectedMapping = createAction(
  `${NAMESPACE} ActivationOfMapping`,
  props<{ templateId: string; mappingId: string; activate: boolean }>(),
);
export const activationOfSelectedMappingComplete = createAction(
  `${NAMESPACE} ActivationOfMappingComplete`,
  props<{ mapping: TemplateMapping }>(),
);
export const activationOfSelectedMappingError = createAction(`${NAMESPACE} ActivationOfMappingError`, props<{ err: HttpErrorResponse }>());

export const selectMapping = createAction(`${NAMESPACE} SelectMapping`, props<{ templateId: string; mappingId: string }>());
export const selectMappingComplete = createAction(`${NAMESPACE} SelectMappingComplete`, props<{ mapping: TemplateMapping }>());
export const selectMappingError = createAction(`${NAMESPACE} SelectMappingError`, props<{ err: HttpErrorResponse }>());

export const goToMappingActions = createAction(`${NAMESPACE} GoToMappingActions`, props<{ templateId: string; mappingId: string }>());
export const goToMappingListPage = createAction(`${NAMESPACE} GoToMappingListPage`, props<{ templateId: string }>());

export const getMappingContents = createAction(`${NAMESPACE} GetMappingContents`, props<{ templateId: string; mappingId: string }>());
export const getMappingContentsComplete = createAction(
  `${NAMESPACE} GetMappingContentsComplete`,
  props<{ contents: TemplateMappingContent[] }>(),
);
export const getMappingContentsError = createAction(`${NAMESPACE} GetMappingContentsError`, props<{ err: HttpErrorResponse }>());

export const updateMappingContent = createAction(
  `${NAMESPACE} UpdateMappingContent`,
  props<{ templateId: string; mappingId: string; tagName: string; content: UpdateTemplateMappingContentRequest }>(),
);
export const updateMappingContentComplete = createAction(
  `${NAMESPACE} UpdateMappingContentComplete`,
  props<{ content: TemplateMappingContent; templateId: string; mappingId: string }>(),
);
export const updateMappingContentError = createAction(`${NAMESPACE} UpdateMappingContentError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedMappingContent = createAction(
  `${NAMESPACE} DeleteMappingContent`,
  props<{ templateId: string; mappingId: string; tagName: string }>(),
);
export const deleteSelectedMappingContentComplete = createAction(
  `${NAMESPACE} DeleteMappingContentComplete`,
  props<{ templateId: string; mappingId: string }>(),
);
export const deleteSelectedMappingContentError = createAction(
  `${NAMESPACE} DeleteMappingContentError`,
  props<{ err: HttpErrorResponse }>(),
);

export const selectMappingContent = createAction(
  `${NAMESPACE} SelectMappingContent`,
  props<{ templateId: string; mappingId: string; tagName: string }>(),
);
export const selectMappingContentComplete = createAction(
  `${NAMESPACE} SelectMappingContentComplete`,
  props<{ content: TemplateMappingContent }>(),
);
export const selectMappingContentError = createAction(`${NAMESPACE} SelectMappingContentError`, props<{ err: HttpErrorResponse }>());

export const goToMappingContentActions = createAction(
  `${NAMESPACE} GoToMappingContentActions`,
  props<{ templateId: string; mappingId: string; tagName: string }>(),
);
export const goToMappingContentListPage = createAction(
  `${NAMESPACE} GoToMappingContentListPage`,
  props<{ templateId: string; mappingId: string }>(),
);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
