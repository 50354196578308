import { HttpErrorResponse } from '@angular/common/http';
import {
  AddRoleDefaultRequest,
  AddRoleRequest,
  CreateRoleAttrubuteRequest,
  PermissionTypes,
  RoleAttribute,
  RoleAttributeListItem,
  RoleDefault,
  RoleInfo,
  UpdateRoleAttributeRequest,
} from '@models/authorization';
import { createAction, props } from '@ngrx/store';
import { UpdateRoleRequestFrontend } from './roles.model';
import { State } from './roles.reducer';

const NAMESPACE = '[Authorization Roles]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ roles: RoleInfo[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addNewRole = createAction(`${NAMESPACE} AddNewRole`, props<{ role: AddRoleRequest }>());
export const addNewRoleComplete = createAction(`${NAMESPACE} AddNewRoleComplete`, props<{ role: RoleInfo }>());
export const addNewRoleError = createAction(`${NAMESPACE} AddNewRoleError`, props<{ err: HttpErrorResponse }>());

export const selectRole = createAction(`${NAMESPACE} SelectRole`, props<{ id: string }>());
export const selectRoleComplete = createAction(`${NAMESPACE} SelectRoleComplete`, props<{ role: RoleInfo }>());
export const selectRoleError = createAction(`${NAMESPACE} SelectRoleError`, props<{ err: HttpErrorResponse }>());

export const getRolePermissions = createAction(`${NAMESPACE} GetRolePermissions`, props<{ id: string }>());
export const getRolePermissionsComplete = createAction(
  `${NAMESPACE} GetRolePermissionsComplete`,
  props<{ permissions: PermissionTypes[] }>(),
);
export const getRolePermissionsError = createAction(`${NAMESPACE} GetRolePermissionsError`, props<{ err: HttpErrorResponse }>());

export const getRoleDefaults = createAction(`${NAMESPACE} GetRoleDefaults`, props<{ id: string }>());
export const getRoleDefaultsComplete = createAction(`${NAMESPACE} GetRoleDefaultsComplete`, props<{ defaults: RoleDefault[] }>());
export const getRoleDefaultsError = createAction(`${NAMESPACE} GetRoleDefaultsError`, props<{ err: HttpErrorResponse }>());

export const addRoleDefault = createAction(`${NAMESPACE} AddRoleDefault`, props<{ addRoleDefaultRequest: AddRoleDefaultRequest }>());
export const addRoleDefaultComplete = createAction(`${NAMESPACE} AddRoleDefaultComplete`, props<{ defaults: RoleDefault[] }>());
export const addRoleDefaultError = createAction(`${NAMESPACE} AddRoleDefaultError`, props<{ err: HttpErrorResponse }>());

export const deleteRoleDefault = createAction(`${NAMESPACE} DeleteRoleDefault`, props<{ roleDefaultId: string; roleId: string }>());
export const deleteRoleDefaultComplete = createAction(`${NAMESPACE} DeleteRoleDefaultComplete`, props<{ roleDefaultId: string }>());
export const deleteRoleDefaultError = createAction(`${NAMESPACE} DeleteRoleDefaultError`, props<{ err: HttpErrorResponse }>());

export const getRoleAttributes = createAction(`${NAMESPACE} GetRoleAttributes`, props<{ roleId: string }>());
export const getRoleAttributesComplete = createAction(
  `${NAMESPACE} GetRoleAttributesComplete`,
  props<{ attributes: RoleAttributeListItem[] }>(),
);
export const getRoleAttributesError = createAction(`${NAMESPACE} GetRoleAttributesError`, props<{ err: HttpErrorResponse }>());

export const addRoleAttribute = createAction(
  `${NAMESPACE} AddRoleAttribute`,
  props<{ addRoleAttributeRequest: CreateRoleAttrubuteRequest }>(),
);
export const addRoleAttributeComplete = createAction(`${NAMESPACE} AddRoleAttributeComplete`, props<{ attribute: RoleAttribute }>());
export const addRoleAttributeError = createAction(`${NAMESPACE} AddRoleAttributeError`, props<{ err: HttpErrorResponse }>());

export const deleteActiveRoleAttribute = createAction(`${NAMESPACE} DeleteActiveRoleAttribute`);
export const deleteActiveRoleAttributeComplete = createAction(
  `${NAMESPACE} DeleteActiveRoleAttributeComplete`,
  props<{ roleId: string }>(),
);
export const deleteActiveRoleAttributeError = createAction(
  `${NAMESPACE} DeleteActiveRoleAttributeError`,
  props<{ err: HttpErrorResponse }>(),
);

export const updateRoleAttribute = createAction(
  `${NAMESPACE} UpdateRoleAttribute`,
  props<{ attributeId: string; attribute: UpdateRoleAttributeRequest }>(),
);
export const updateRoleAttributeComplete = createAction(`${NAMESPACE} UpdateRoleAttributeComplete`, props<{ attribute: RoleAttribute }>());
export const updateRoleAttributeError = createAction(`${NAMESPACE} UpdateRoleAttributeError`, props<{ err: HttpErrorResponse }>());

export const selectRoleAttribute = createAction(`${NAMESPACE} SelectRoleAttribute`, props<{ id: string }>());
export const selectRoleAttributeComplete = createAction(`${NAMESPACE} SelectRoleAttributeComplete`, props<{ attribute: RoleAttribute }>());
export const selectRoleAttributeError = createAction(`${NAMESPACE} SelectRoleAttributeError`, props<{ err: HttpErrorResponse }>());

export const updateRole = createAction(`${NAMESPACE} UpdateRole`, props<{ role: UpdateRoleRequestFrontend }>());
export const updateRoleComplete = createAction(`${NAMESPACE} UpdateRoleComplete`, props<{ role: RoleInfo }>());
export const updateRoleError = createAction(`${NAMESPACE} UpdateRoleError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedRole = createAction(`${NAMESPACE} DeleteSelectedRole`);
export const deleteSelectedRoleComplete = createAction(`${NAMESPACE} DeleteSelectedRoleComplete`);
export const deleteSelectedRoleError = createAction(`${NAMESPACE} DeleteSelectedRoleError`, props<{ err: HttpErrorResponse }>());

export const goToRoleActions = createAction(`${NAMESPACE} GoToRoleActions`, props<{ id: string }>());
export const goToRoleAttributesList = createAction(`${NAMESPACE} GoToRoleAttributesList`, props<{ id: string }>());
export const goToRoleAttributeActions = createAction(`${NAMESPACE} GoToRoleAttributeActions`, props<{ attributeId: string }>());
export const goToSelectedRoleActions = createAction(`${NAMESPACE} GoToSelectedRoleActions`);
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
