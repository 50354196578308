import { ProductRequest } from '@models/products-and-orders/requests';
import { createReducer, on } from '@ngrx/store';
import { sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './requests.actions';

export interface State {
  isLoading: boolean;
  requests: ProductRequest[];

  selectedRequest: ProductRequest | null;
  selectedRequestLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  requests: [],

  selectedRequest: null,
  selectedRequestLoading: false,
};

export const requestsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { requests }) => ({
    ...state,
    requests: sortAlphabetically(requests, (a, b) => {
      const dateA = a.lastUpdatedBy?.date || a.createdBy.date;
      const dateB = b.lastUpdatedBy?.date || b.createdBy.date;

      return dateA < dateB ? 1 : -1;
    }),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectRequest, state => ({ ...state, selectedRequestLoading: true })),
  on(fromActions.selectRequestComplete, (state, { request }) => ({ ...state, selectedRequestLoading: false, selectedRequest: request })),
  on(fromActions.selectRequestError, state => ({ ...state, selectedRequestLoading: false })),
);
